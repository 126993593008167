import React, { useEffect } from 'react';

import { ConfirmModal } from '@grafana/ui';

import { useDashboardRestore } from './useDashboardRestore';
export interface RevertDashboardModalProps {
  hideModal: () => void;
  version: number;
}

export const RevertDashboardModal = ({ hideModal, version }: RevertDashboardModalProps) => {
  // TODO: how should state.error be handled?
  const { state, onRestoreDashboard } = useDashboardRestore(version);

  useEffect(() => {
    if (!state.loading && state.value) {
      hideModal();
    }
  }, [state, hideModal]);

  return (
    <ConfirmModal
      isOpen={true}
      title="恢复版本"
      icon="history"
      onDismiss={hideModal}
      onConfirm={onRestoreDashboard}
      body={<p>确定恢复为版本{version}? 所有修改即将丢失</p>}
      confirmText={`是的, 恢复为版本 ${version}`}
    />
  );
};
