import { DataQuery, ReducerID, SelectableValue } from '@grafana/data'

import { EvalFunction } from '../alerting/state/alertDef'

/**
 * MATCHES a constant in DataSourceWithBackend
 */
export const ExpressionDatasourceUID = '__expr__'

export enum ExpressionQueryType {
  math = 'math',
  reduce = 'reduce',
  resample = 'resample',
  classic = 'classic_conditions',
  threshold = 'threshold',
}

export const getExpressionLabel = (type: ExpressionQueryType) => {
  switch (type) {
    case ExpressionQueryType.math:
      return 'Math'
    case ExpressionQueryType.reduce:
      return 'Reduce'
    case ExpressionQueryType.resample:
      return 'Resample'
    case ExpressionQueryType.classic:
      return 'Classic condition'
    case ExpressionQueryType.threshold:
      return 'Threshold'
  }
}

export const expressionTypes: Array<SelectableValue<ExpressionQueryType>> = [
  {
    value: ExpressionQueryType.math,
    label: 'Math',
    description: '数学运算.',
  },
  {
    value: ExpressionQueryType.reduce,
    label: 'Reduce',
    description: 'reduce方法',
  },
  {
    value: ExpressionQueryType.resample,
    label: 'Resample',
    description: '重新采样',
  },
  {
    value: ExpressionQueryType.classic,
    label: 'Classic condition',
    description: '检查是否满足条件',
  },
  {
    value: ExpressionQueryType.threshold,
    label: 'Threshold',
    description: '检查是否达到阈值',
  },
]

export const reducerTypes: Array<SelectableValue<string>> = [
  { value: ReducerID.min, label: 'Min', description: '最小值' },
  { value: ReducerID.max, label: 'Max', description: '最大值' },
  { value: ReducerID.mean, label: 'Mean', description: '算术平均值' },
  { value: ReducerID.sum, label: 'Sum', description: '和' },
  { value: ReducerID.count, label: 'Count', description: '计数' },
  { value: ReducerID.last, label: 'Last', description: '最后一个值' },
]

export enum ReducerMode {
  Strict = '', // backend API wants an empty string to support "strict" mode
  ReplaceNonNumbers = 'replaceNN',
  DropNonNumbers = 'dropNN',
}

export const reducerModes: Array<SelectableValue<ReducerMode>> = [
  {
    value: ReducerMode.Strict,
    label: 'Strict',
    description: '如果数据含有非数值，运行NaN',
  },
  {
    value: ReducerMode.DropNonNumbers,
    label: 'Drop Non-numeric Values',
    description: '排除NaN和正负无穷',
  },
  {
    value: ReducerMode.ReplaceNonNumbers,
    label: 'Replace Non-numeric Values',
    description: '将NaN和正负无穷替换为常量',
  },
]

export const downsamplingTypes: Array<SelectableValue<string>> = [
  { value: ReducerID.last, label: 'Last', description: '用最后一个值填充' },
  { value: ReducerID.min, label: 'Min', description: '用最小值填充' },
  { value: ReducerID.max, label: 'Max', description: '用最大值填充' },
  { value: ReducerID.mean, label: 'Mean', description: '用平均值填充' },
  { value: ReducerID.sum, label: 'Sum', description: '用和填充' },
]

export const upsamplingTypes: Array<SelectableValue<string>> = [
  { value: 'pad', label: 'pad', description: '用最后一个值' },
  { value: 'backfilling', label: 'backfilling', description: '用下一个值' },
  { value: 'fillna', label: 'fillna', description: '用NaN填充' },
]

export const thresholdFunctions: Array<SelectableValue<EvalFunction>> = [
  { value: EvalFunction.IsAbove, label: '高于' },
  { value: EvalFunction.IsBelow, label: '低于' },
  { value: EvalFunction.IsWithinRange, label: '在范围内' },
  { value: EvalFunction.IsOutsideRange, label: '在范围外' },
]

/**
 * For now this is a single object to cover all the types.... would likely
 * want to split this up by type as the complexity increases
 */
export interface ExpressionQuery extends DataQuery {
  type: ExpressionQueryType
  reducer?: string
  expression?: string
  window?: string
  downsampler?: string
  upsampler?: string
  conditions?: ClassicCondition[]
  settings?: ExpressionQuerySettings
}

export interface ExpressionQuerySettings {
  mode?: ReducerMode
  replaceWithValue?: number
}

export interface ClassicCondition {
  evaluator: {
    params: number[]
    type: EvalFunction
  }
  operator?: {
    type: string
  }
  query: {
    params: string[]
  }
  reducer: {
    params: []
    type: ReducerType
  }
  type: 'query'
}

export type ReducerType =
  | 'avg'
  | 'min'
  | 'max'
  | 'sum'
  | 'count'
  | 'last'
  | 'median'
  | 'diff'
  | 'diff_abs'
  | 'percent_diff'
  | 'percent_diff_abs'
  | 'count_non_null'
