import { DataFrame, FieldConfigSource, PanelData, PanelPlugin } from '@grafana/data';
import { t } from 'app/core/internationalization';

import { DashboardModel, PanelModel } from '../../state';

export interface PanelEditorTab {
  id: string;
  text: string;
  active: boolean;
  icon: string;
}

export enum PanelEditorTabId {
  Query = 'query',
  Transform = 'transform',
  Visualize = 'visualize',
  Alert = 'alert',
}

export enum DisplayMode {
  Fill = 0,
  Fit = 1,
  Exact = 2,
}

export enum PanelEditTableToggle {
  Off = 0,
  Table = 1,
}

export const displayModes = [
  {
    // value: DisplayMode.Fill, label: t('dashboard.panel-editor.toolbar.fill.title', 'Fill'), description: t('dashboard.panel-editor.toolbar.fill.tooltip', 'Use all available space')
    value: DisplayMode.Fill,
    label: '铺满',
    description: '填充整个面板',
  },
  // { value: DisplayMode.Exact, label: t('dashboard.panel-editor.toolbar.actual.title', 'Actual'), description: t('dashboard.panel-editor.toolbar.actual.tooltip', 'Make same size as on the dashboard') },
  { value: DisplayMode.Exact, label: '实际', description: '原始尺寸' },
];

export const panelEditTableModes = [
  {
    value: PanelEditTableToggle.Off,
    label: t('dashboard.panel-editor.visualization-view.title', 'Visualization'),
    description: t('dashboard.panel-editor.visualization-view.description', 'Show using selected visualization'),
  },
  {
    value: PanelEditTableToggle.Table,
    label: t('dashboard.panel-editor.table-view.title', 'Table'),
    description: t('dashboard.panel-editor.table-view.description', 'Show raw data in table form'),
  },
];

/** @internal */
export interface Props {
  plugin: PanelPlugin;
  config: FieldConfigSource;
  onChange: (config: FieldConfigSource) => void;
  /* Helpful for IntelliSense */
  data: DataFrame[];
}

export interface OptionPaneRenderProps {
  panel: PanelModel;
  plugin: PanelPlugin;
  data?: PanelData;
  dashboard: DashboardModel;
  instanceState: any;
  onPanelConfigChange: (configKey: keyof PanelModel, value: unknown) => void;
  onPanelOptionsChanged: (options: PanelModel['options']) => void;
  onFieldConfigsChange: (config: FieldConfigSource) => void;
}

export interface OptionPaneItemOverrideInfo {
  type: 'data' | 'rule';
  onClick?: () => void;
  tooltip: string;
  description: string;
}

export enum VisualizationSelectPaneTab {
  Visualizations,
  LibraryPanels,
  Suggestions,
  Widgets,
}
