import { css } from '@emotion/css'
import React, { ChangeEvent } from 'react'

import { GrafanaTheme2 } from '@grafana/data'
import { Stack } from '@grafana/experimental'
import { Icon, InlineField, InlineLabel, TextArea, Toggletip, useStyles2 } from '@grafana/ui'

import { ExpressionQuery } from '../types'

interface Props {
  labelWidth: number | 'auto'
  query: ExpressionQuery
  onChange: (query: ExpressionQuery) => void
  onRunQuery: () => void
}

const mathPlaceholder = 'query中使用的数学运算。可以在query中使用${refId} 如 $A, $B, $C 等\n' + '加法: $A + $B > 10'

export const Math = ({ labelWidth, onChange, query, onRunQuery }: Props) => {
  const onExpressionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange({ ...query, expression: event.target.value })
  }

  const styles = useStyles2(getStyles)

  const executeQuery = () => {
    if (query.expression) {
      onRunQuery()
    }
  }

  return (
    <Stack direction="row">
      <InlineField
        label={
          <InlineLabel width="auto">
            <Toggletip
              fitContent
              content={
                <div className={styles.documentationContainer}>
                  <div>
                    query中运行数学运算. 可以在query中使用 {'${refId}'} 如 $A, $B, $C 等
                    <br />
                    比如：<code>$A + $B</code>
                  </div>
                  <header className={styles.documentationHeader}>Available Math functions</header>
                  <div className={styles.documentationFunctions}>
                    <DocumentedFunction name="abs" description="绝对值" />
                    <DocumentedFunction name="is_inf" description="值为（正负）无穷则返回 1，其它则为 0。" />
                    <DocumentedFunction name="is_nan" description="值不是数则返回 1，其它则为 0。" />
                    <DocumentedFunction name="is_null" description="值为null则返回 1，其它则为 0。" />
                    <DocumentedFunction name="is_number" description="值是数则返回 1，其它则为 0。" />
                    <DocumentedFunction name="log" description="自然对数" />
                    <DocumentedFunction name="inf, infn, nan, and null" description="无穷、负无穷、非数、null" />
                    <DocumentedFunction name="round" description="四舍五入取整" />
                    <DocumentedFunction name="ceil" description="向上取整" />
                    <DocumentedFunction name="floor" description="向下取整" />
                  </div>
                </div>
              }
              title={
                <Stack gap={1} direction="row">
                  <Icon name="book-open" /> Math operator
                </Stack>
              }
              footer={
                <div>
                  See our additional documentation on{' '}
                  <a
                    className={styles.documentationLink}
                    target="_blank"
                    href="https://grafana.com/docs/grafana/latest/panels/query-a-data-source/use-expressions-to-manipulate-data/about-expressions/#math"
                    rel="noreferrer"
                  >
                    <Icon size="xs" name="external-link-alt" /> Math expressions
                  </a>
                  .
                </div>
              }
              closeButton={true}
              placement="bottom-start"
            >
              <div className={styles.info}>
                表达式 <Icon name="info-circle" />
              </div>
            </Toggletip>
          </InlineLabel>
        }
        labelWidth={labelWidth}
        grow={true}
        shrink={true}
      >
        <TextArea
          value={query.expression}
          onChange={onExpressionChange}
          rows={1}
          placeholder={mathPlaceholder}
          onBlur={executeQuery}
          style={{ minWidth: 250, lineHeight: '26px', minHeight: 32 }}
        />
      </InlineField>
    </Stack>
  )
}

interface DocumentedFunctionProps {
  name: string
  description: React.ReactNode
}
const DocumentedFunction = ({ name, description }: DocumentedFunctionProps) => {
  const styles = useStyles2(getDocumentedFunctionStyles)

  return (
    <>
      <span className={styles.name}>{name}</span>
      <span className={styles.description}>{description}</span>
    </>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  documentationHeader: css`
    font-size: ${theme.typography.h5.fontSize};
    font-weight: ${theme.typography.h5.fontWeight};
  `,
  documentationLink: css`
    color: ${theme.colors.text.link};
  `,
  documentationContainer: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${theme.spacing(2)};

    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `,
  documentationFunctions: css`
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: ${theme.spacing(2)};
  `,
  info: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${theme.spacing(1)};
  `,
})

const getDocumentedFunctionStyles = (theme: GrafanaTheme2) => ({
  name: css`
    font-weight: ${theme.typography.fontWeightBold};
  `,
  description: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.disabled};
  `,
})
