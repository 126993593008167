import { PanelPlugin } from '@grafana/data';
import { BigValueColorMode, BigValueGraphMode, BigValueJustifyMode, BigValueTextMode } from '@grafana/schema';
import { commonOptionsBuilder, sharedSingleStatMigrationHandler } from '@grafana/ui';

import { statPanelChangedHandler } from './StatMigrations';
import { StatPanel } from './StatPanel';
import { addStandardDataReduceOptions, addOrientationOption } from './common';
import { defaultOptions, Options } from './panelcfg.gen';
import { StatSuggestionsSupplier } from './suggestions';

export const plugin = new PanelPlugin<Options>(StatPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    const mainCategory = ['Stat 图风格'];

    addStandardDataReduceOptions(builder);
    addOrientationOption(builder, mainCategory);
    commonOptionsBuilder.addTextSizeOptions(builder);

    builder.addSelect({
      path: 'textMode',
      name: '文字模式',
      description: '空值只展示 名称 或 数值 的展示',
      category: mainCategory,
      settings: {
        options: [
          { value: BigValueTextMode.Auto, label: '自动' },
          { value: BigValueTextMode.Value, label: '数值' },
          { value: BigValueTextMode.ValueAndName, label: '数值与名称' },
          { value: BigValueTextMode.Name, label: '名称' },
          { value: BigValueTextMode.None, label: '无' },
        ],
      },
      defaultValue: defaultOptions.textMode,
    });

    builder
      .addSelect({
        path: 'colorMode',
        name: '色彩模式',
        defaultValue: BigValueColorMode.Value,
        category: mainCategory,
        settings: {
          options: [
            { value: BigValueColorMode.None, label: '不上色' },
            { value: BigValueColorMode.Value, label: '数值上色' },
            { value: BigValueColorMode.Background, label: '背景上色-渐变' },
            { value: BigValueColorMode.BackgroundSolid, label: '背景上色-纯色' },
          ],
        },
      })
      .addRadio({
        path: 'graphMode',
        name: '图像模式',
        description: '展示统计图',
        category: mainCategory,
        defaultValue: defaultOptions.graphMode,
        settings: {
          options: [
            { value: BigValueGraphMode.None, label: '无' },
            { value: BigValueGraphMode.Area, label: '区域' },
          ],
        },
      })
      .addRadio({
        path: 'justifyMode',
        name: '文字对齐方式',
        defaultValue: defaultOptions.justifyMode,
        category: mainCategory,
        settings: {
          options: [
            { value: BigValueJustifyMode.Auto, label: '自动' },
            { value: BigValueJustifyMode.Center, label: '居中' },
          ],
        },
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setSuggestionsSupplier(new StatSuggestionsSupplier())
  .setMigrationHandler(sharedSingleStatMigrationHandler);
