import { FieldColorModeId, FieldConfigProperty, PanelPlugin } from '@grafana/data';
import { commonOptionsBuilder } from '@grafana/ui';

import { addStandardDataReduceOptions } from '../stat/common';

import { PieChartPanel } from './PieChartPanel';
import { PieChartPanelChangedHandler } from './migrations';
import { Options, FieldConfig, PieChartType, PieChartLabels, PieChartLegendValues } from './panelcfg.gen';
import { PieChartSuggestionsSupplier } from './suggestions';

export const plugin = new PanelPlugin<Options, FieldConfig>(PieChartPanel)
  .setPanelChangeHandler(PieChartPanelChangedHandler)
  .useFieldConfig({
    disableStandardOptions: [FieldConfigProperty.Thresholds],
    standardOptions: {
      [FieldConfigProperty.Color]: {
        settings: {
          byValueSupport: false,
          bySeriesSupport: true,
          preferThresholdsMode: false,
        },
        defaultValue: {
          mode: FieldColorModeId.PaletteClassic,
        },
      },
    },
    useCustomConfig: (builder) => {
      commonOptionsBuilder.addHideFrom(builder);
    },
  })
  .setPanelOptions((builder) => {
    addStandardDataReduceOptions(builder);
    builder
      .addRadio({
        name: '饼图类型',
        description: '如何绘制饼图',
        path: 'pieType',
        settings: {
          options: [
            { value: PieChartType.Pie, label: '实心' },
            { value: PieChartType.Donut, label: '圈图' },
          ],
        },
        defaultValue: PieChartType.Pie,
      })
      .addMultiSelect({
        name: '标签',
        path: 'displayLabels',
        description: '饼图上标签展示内容',
        settings: {
          options: [
            { value: PieChartLabels.Percent, label: '百分比' },
            { value: PieChartLabels.Name, label: '名称' },
            { value: PieChartLabels.Value, label: '数值' },
          ],
        },
      });

    commonOptionsBuilder.addTooltipOptions(builder);
    commonOptionsBuilder.addLegendOptions(builder, false);

    builder.addMultiSelect({
      name: '图例',
      path: 'legend.values',
      category: ['图例'],
      settings: {
        options: [
          { value: PieChartLegendValues.Percent, label: '百分比' },
          { value: PieChartLegendValues.Value, label: '数值' },
        ],
      },
      showIf: (c) => c.legend.showLegend !== false,
    });
  })
  .setSuggestionsSupplier(new PieChartSuggestionsSupplier());
