import { css } from '@emotion/css'
import React, { useMemo } from 'react'

import { GrafanaTheme2 } from '@grafana/data'
import { Modal, useStyles2 } from '@grafana/ui'
import { getModKey } from 'app/core/utils/browser'

const getShortcuts = (modKey: string) => {
  return {
    Global: [
      { keys: ['g', 'h'], description: '回到主面板' },
      { keys: ['g', 'd'], description: '跳到面板' },
      { keys: ['g', 'e'], description: '跳到探索' },
      { keys: ['g', 'p'], description: '跳到资料' },
      { keys: [`${modKey} + k`], description: '搜索' },
      { keys: ['esc'], description: '退出编辑/设置界面' },
      { keys: ['h'], description: '显示所有键盘快捷方式' },
      { keys: ['c', 't'], description: '修改主题' },
    ],
    Dashboard: [
      { keys: [`${modKey}+s`], description: '保存仪表板' },
      { keys: ['d', 'r'], description: '刷新所有面板' },
      { keys: ['d', 's'], description: '仪表板设置' },
      { keys: ['d', 'v'], description: '切换 in-active / view 模式' },
      { keys: ['d', 'k'], description: '切换自助台模式' },
      { keys: ['d', 'E'], description: '展开所有行' },
      { keys: ['d', 'C'], description: '收拢所有行' },
      { keys: ['d', 'a'], description: '切换面板自适应（试验功能）' },
      { keys: [`${modKey} + o`], description: '切换十字光标跟随' },
      { keys: ['d', 'l'], description: '切换所有面板的图例展示' },
      { keys: ['d', 'x'], description: '所有面板切换 exemplars' },
    ],
    'Focused Panel': [
      { keys: ['e'], description: '编辑面板' },
      { keys: ['v'], description: '最大化面板' },
      { keys: ['p', 's'], description: '分享面板' },
      { keys: ['p', 'd'], description: '复制面板' },
      { keys: ['p', 'r'], description: '删除面板' },
      { keys: ['p', 'l'], description: '切换面板图例的展示' },
    ],
    'Time Range': [
      { keys: ['t', 'z'], description: '增加时间范围' },
      {
        keys: ['t', '←'],
        description: '向后（过去）调整时间范围',
      },
      {
        keys: ['t', '→'],
        description: '向前（未来）调整时间范围',
      },
      {
        keys: ['t', 'a'],
        description: '使用绝对时间',
      },
    ],
  }
}

export interface HelpModalProps {
  onDismiss: () => void
}

export const HelpModal = ({ onDismiss }: HelpModalProps): JSX.Element => {
  const styles = useStyles2(getStyles)
  const modKey = useMemo(() => getModKey(), [])
  const shortcuts = useMemo(() => getShortcuts(modKey), [modKey])
  return (
    <Modal title="Shortcuts" isOpen onDismiss={onDismiss} onClickBackdrop={onDismiss}>
      <div className={styles.categories}>
        {Object.entries(shortcuts).map(([category, shortcuts], i) => (
          <div className={styles.shortcutCategory} key={i}>
            <table className={styles.shortcutTable}>
              <tbody>
                <tr>
                  <th className={styles.shortcutTableCategoryHeader} colSpan={2}>
                    {category}
                  </th>
                </tr>
                {shortcuts.map((shortcut, j) => (
                  <tr key={`${i}-${j}`}>
                    <td className={styles.shortcutTableKeys}>
                      {shortcut.keys.map((key, k) => (
                        <span className={styles.shortcutTableKey} key={`${i}-${j}-${k}`}>
                          {key}
                        </span>
                      ))}
                    </td>
                    <td className={styles.shortcutTableDescription}>{shortcut.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </Modal>
  )
}

function getStyles(theme: GrafanaTheme2) {
  return {
    titleDescription: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      font-weight: ${theme.typography.bodySmall.fontWeight};
      color: ${theme.colors.text.disabled};
      padding-bottom: ${theme.spacing(2)};
    `,
    categories: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
    `,
    shortcutCategory: css`
      width: 50%;
      font-size: ${theme.typography.bodySmall.fontSize};
    `,
    shortcutTable: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    shortcutTableCategoryHeader: css`
      font-weight: normal;
      font-size: ${theme.typography.h6.fontSize};
      text-align: left;
    `,
    shortcutTableDescription: css`
      text-align: left;
      color: ${theme.colors.text.disabled};
      width: 99%;
      padding: ${theme.spacing(1, 2)};
    `,
    shortcutTableKeys: css`
      white-space: nowrap;
      width: 1%;
      text-align: right;
      color: ${theme.colors.text.primary};
    `,
    shortcutTableKey: css`
      display: inline-block;
      text-align: center;
      margin-right: ${theme.spacing(0.5)};
      padding: 3px 5px;
      font:
        11px Consolas,
        'Liberation Mono',
        Menlo,
        Courier,
        monospace;
      line-height: 10px;
      vertical-align: middle;
      border: solid 1px ${theme.colors.border.medium};
      border-radius: ${theme.shape.borderRadius(3)};
      color: ${theme.colors.text.primary};
      background-color: ${theme.colors.background.secondary};
    `,
  }
}
