import { SelectableValue } from '@grafana/data';
import {
  AxisPlacement,
  BarAlignment,
  GraphDrawStyle,
  GraphGradientMode,
  GraphTresholdsStyleMode,
  LineInterpolation,
  VisibilityMode,
  StackingMode,
} from '@grafana/schema';

/**
 * @alpha
 */
export const graphFieldOptions = {
  drawStyle: [
    { label: '直线', value: GraphDrawStyle.Line },
    { label: '柱状', value: GraphDrawStyle.Bars },
    { label: '点图', value: GraphDrawStyle.Points },
  ] as Array<SelectableValue<GraphDrawStyle>>,

  lineInterpolation: [
    { description: '线性', value: LineInterpolation.Linear, icon: 'gf-interpolation-linear' },
    { description: '平滑', value: LineInterpolation.Smooth, icon: 'gf-interpolation-smooth' },
    { description: 'Step before', value: LineInterpolation.StepBefore, icon: 'gf-interpolation-step-before' },
    { description: 'Step after', value: LineInterpolation.StepAfter, icon: 'gf-interpolation-step-after' },
  ] as Array<SelectableValue<LineInterpolation>>,

  barAlignment: [
    { description: '靠前', value: BarAlignment.Before, icon: 'gf-bar-alignment-before' },
    { description: '居中', value: BarAlignment.Center, icon: 'gf-bar-alignment-center' },
    { description: '靠后', value: BarAlignment.After, icon: 'gf-bar-alignment-after' },
  ] as Array<SelectableValue<BarAlignment>>,

  showPoints: [
    { label: '自动', value: VisibilityMode.Auto },
    { label: '展示', value: VisibilityMode.Always },
    { label: '不展示', value: VisibilityMode.Never },
  ] as Array<SelectableValue<VisibilityMode>>,

  axisPlacement: [
    { label: '自动', value: AxisPlacement.Auto },
    { label: '左边', value: AxisPlacement.Left },
    { label: '右边', value: AxisPlacement.Right },
    { label: '不展示', value: AxisPlacement.Hidden },
  ] as Array<SelectableValue<AxisPlacement>>,

  fillGradient: [
    { label: '无', value: GraphGradientMode.None },
    { label: '透明度', value: GraphGradientMode.Opacity },
    { label: '色相', value: GraphGradientMode.Hue },
    {
      label: '使用预置配色方案',
      value: GraphGradientMode.Scheme,
      description: '在 标准选项 中设定配色方案',
    },
  ] as Array<SelectableValue<GraphGradientMode>>,

  stacking: [
    { label: '无', value: StackingMode.None },
    { label: '数值叠加', value: StackingMode.Normal },
    { label: '百分比叠加', value: StackingMode.Percent },
  ] as Array<SelectableValue<StackingMode>>,

  thresholdsDisplayModes: [
    { label: '无', value: GraphTresholdsStyleMode.Off },
    { label: '画线', value: GraphTresholdsStyleMode.Line },
    { label: '虚线', value: GraphTresholdsStyleMode.Dashed },
    { label: '区域填充', value: GraphTresholdsStyleMode.Area },
    { label: '区域填充 + 画线', value: GraphTresholdsStyleMode.LineAndArea },
    { label: '区域填充 + 画虚线', value: GraphTresholdsStyleMode.DashedAndArea },
  ] as Array<SelectableValue<GraphTresholdsStyleMode>>,
};
