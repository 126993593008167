import { toHex, sci, toHex0x, toPercent, toPercentUnit } from './arithmeticFormatters'
import {
  dateTimeAsIso,
  dateTimeAsIsoNoDateIfToday,
  dateTimeAsUS,
  dateTimeAsUSNoDateIfToday,
  getDateTimeAsLocalFormat,
  getDateTimeAsLocalFormatNoDateIfToday,
  dateTimeFromNow,
  toClockMilliseconds,
  toClockSeconds,
  toDays,
  toDurationInDaysHoursMinutesSeconds,
  toDurationInHoursMinutesSeconds,
  toDurationInMilliseconds,
  toDurationInSeconds,
  toHours,
  toMicroSeconds,
  toMilliSeconds,
  toMinutes,
  toNanoSeconds,
  toSeconds,
  toTimeTicks,
  dateTimeSystemFormatter,
} from './dateTimeFormatters'
import { binaryPrefix, currency, SIPrefix } from './symbolFormatters'
import {
  locale,
  scaledUnits,
  simpleCountUnit,
  toFixedUnit,
  ValueFormatCategory,
  stringFormater,
  booleanValueFormatter,
} from './valueFormats'

export const getCategories = (): ValueFormatCategory[] => [
  {
    name: '常用',
    formats: [
      { name: '无单位数字', id: 'none', fn: toFixedUnit('') },
      { name: '字符串', id: 'string', fn: stringFormater },
      {
        name: '数字',
        id: 'short',
        fn: scaledUnits(1000, ['', ' K', ' Mil', ' Bil', ' Tri', ' Quadr', ' Quint', ' Sext', ' Sept']),
      },
      { name: '百分比 (0-100)', id: 'percent', fn: toPercent },
      { name: '百分比 (0.0-1.0)', id: 'percentunit', fn: toPercentUnit },
      { name: '湿度 (%H)', id: 'humidity', fn: toFixedUnit('%H') },
      { name: '分贝', id: 'dB', fn: toFixedUnit('dB') },
      { name: '坎德拉 (cd)', id: 'candela', fn: SIPrefix('cd') },
      { name: '16进制 - 带0x', id: 'hex0x', fn: toHex0x },
      { name: '16进制', id: 'hex', fn: toHex },
      { name: '科学计数法', id: 'sci', fn: sci },
      { name: '本地化格式', id: 'locale', fn: locale },
      { name: '像素', id: 'pixel', fn: toFixedUnit('px') },
    ],
  },
  {
    name: '能量',
    formats: [
      { name: '瓦特 (W)', id: 'watt', fn: SIPrefix('W') },
      { name: '千瓦 (kW)', id: 'kwatt', fn: SIPrefix('W', 1) },
      { name: '兆瓦 (MW)', id: 'megwatt', fn: SIPrefix('W', 2) },
      { name: 'G瓦 (GW)', id: 'gwatt', fn: SIPrefix('W', 3) },
      { name: '毫瓦 (mW)', id: 'mwatt', fn: SIPrefix('W', -1) },
      { name: '瓦/平米 (W/m²)', id: 'Wm2', fn: toFixedUnit('W/m²') },
      { name: '伏安 (VA)', id: 'voltamp', fn: SIPrefix('VA') },
      { name: '千伏安 (kVA)', id: 'kvoltamp', fn: SIPrefix('VA', 1) },
      { name: '无功伏安 (VAr)', id: 'voltampreact', fn: SIPrefix('VAr') },
      { name: '无功千伏安 (kVAr)', id: 'kvoltampreact', fn: SIPrefix('VAr', 1) },
      { name: '瓦时 (Wh)', id: 'watth', fn: SIPrefix('Wh') },
      { name: '瓦时/公斤 (Wh/kg)', id: 'watthperkg', fn: SIPrefix('Wh/kg') },
      { name: '千瓦时 (kWh)', id: 'kwatth', fn: SIPrefix('Wh', 1) },
      { name: '千瓦分钟 (kWm)', id: 'kwattm', fn: SIPrefix('W-Min', 1) },
      { name: '安时 (Ah)', id: 'amph', fn: SIPrefix('Ah') },
      { name: '千安时 (kAh)', id: 'kamph', fn: SIPrefix('Ah', 1) },
      { name: '毫安时 (mAh)', id: 'mamph', fn: SIPrefix('Ah', -1) },
      { name: '焦 (J)', id: 'joule', fn: SIPrefix('J') },
      { name: '电子伏特 (eV)', id: 'ev', fn: SIPrefix('eV') },
      { name: '安培 (A)', id: 'amp', fn: SIPrefix('A') },
      { name: '千安 (kA)', id: 'kamp', fn: SIPrefix('A', 1) },
      { name: '毫安 (mA)', id: 'mamp', fn: SIPrefix('A', -1) },
      { name: '伏 (V)', id: 'volt', fn: SIPrefix('V') },
      { name: '千伏 (kV)', id: 'kvolt', fn: SIPrefix('V', 1) },
      { name: '毫伏 (mV)', id: 'mvolt', fn: SIPrefix('V', -1) },
      { name: '分贝毫瓦 (dBm)', id: 'dBm', fn: SIPrefix('dBm') },
      { name: '毫欧 (mΩ)', id: 'mohm', fn: SIPrefix('Ω', -1) },
      { name: '欧姆 (Ω)', id: 'ohm', fn: SIPrefix('Ω') },
      { name: '千欧姆 (kΩ)', id: 'kohm', fn: SIPrefix('Ω', 1) },
      { name: '兆欧姆 (MΩ)', id: 'Mohm', fn: SIPrefix('Ω', 2) },
      { name: '法拉 (F)', id: 'farad', fn: SIPrefix('F') },
      { name: '微法 (µF)', id: 'µfarad', fn: SIPrefix('F', -2) },
      { name: '纳法 (nF)', id: 'nfarad', fn: SIPrefix('F', -3) },
      { name: '皮法 (pF)', id: 'pfarad', fn: SIPrefix('F', -4) },
      { name: '飞法 (fF)', id: 'ffarad', fn: SIPrefix('F', -5) },
      { name: '亨 (H)', id: 'henry', fn: SIPrefix('H') },
      { name: '毫亨 (mH)', id: 'mhenry', fn: SIPrefix('H', -1) },
      { name: '微亨 (µH)', id: 'µhenry', fn: SIPrefix('H', -2) },
      { name: '流明 (Lm)', id: 'lumens', fn: SIPrefix('Lm') },
    ],
  },
  {
    name: '日期 和 时间',
    formats: [
      { name: 'ISO日期时间 格式', id: 'dateTimeAsIso', fn: dateTimeAsIso },
      { name: 'ISO日期时间 - 当天不含日期', id: 'dateTimeAsIsoNoDateIfToday', fn: dateTimeAsIsoNoDateIfToday },
      { name: '美式日期时间 格式', id: 'dateTimeAsUS', fn: dateTimeAsUS },
      { name: '美式日期时间 - 当天不含日期', id: 'dateTimeAsUSNoDateIfToday', fn: dateTimeAsUSNoDateIfToday },
      { name: '本地日期时间 格式', id: 'dateTimeAsLocal', fn: getDateTimeAsLocalFormat() },
      {
        name: '本地日期时间 - 当天不含日期',
        id: 'dateTimeAsLocalNoDateIfToday',
        fn: getDateTimeAsLocalFormatNoDateIfToday(),
      },
      { name: '默认日期时间格式', id: 'dateTimeAsSystem', fn: dateTimeSystemFormatter },
      { name: '从现在计算', id: 'dateTimeFromNow', fn: dateTimeFromNow },
    ],
  },
  {
    name: '加速度',
    formats: [
      { name: 'Meters/sec²', id: 'accMS2', fn: toFixedUnit('m/sec²') },
      { name: 'Feet/sec²', id: 'accFS2', fn: toFixedUnit('f/sec²') },
      { name: 'G unit', id: 'accG', fn: toFixedUnit('g') },
    ],
  },
  {
    name: '角度',
    formats: [
      { name: 'Degrees (°)', id: 'degree', fn: toFixedUnit('°') },
      { name: 'Radians', id: 'radian', fn: toFixedUnit('rad') },
      { name: 'Gradian', id: 'grad', fn: toFixedUnit('grad') },
      { name: 'Arc Minutes', id: 'arcmin', fn: toFixedUnit('arcmin') },
      { name: 'Arc Seconds', id: 'arcsec', fn: toFixedUnit('arcsec') },
    ],
  },
  {
    name: '面积',
    formats: [
      { name: 'Square Meters (m²)', id: 'areaM2', fn: toFixedUnit('m²') },
      { name: 'Square Feet (ft²)', id: 'areaF2', fn: toFixedUnit('ft²') },
      { name: 'Square Miles (mi²)', id: 'areaMI2', fn: toFixedUnit('mi²') },
      { name: 'Acres (ac)', id: 'acres', fn: toFixedUnit('ac') },
      { name: 'Hectares (ha)', id: 'hectares', fn: toFixedUnit('ha') },
    ],
  },
  {
    name: '计算',
    formats: [
      { name: 'FLOP/s', id: 'flops', fn: SIPrefix('FLOPS') },
      { name: 'MFLOP/s', id: 'mflops', fn: SIPrefix('FLOPS', 2) },
      { name: 'GFLOP/s', id: 'gflops', fn: SIPrefix('FLOPS', 3) },
      { name: 'TFLOP/s', id: 'tflops', fn: SIPrefix('FLOPS', 4) },
      { name: 'PFLOP/s', id: 'pflops', fn: SIPrefix('FLOPS', 5) },
      { name: 'EFLOP/s', id: 'eflops', fn: SIPrefix('FLOPS', 6) },
      { name: 'ZFLOP/s', id: 'zflops', fn: SIPrefix('FLOPS', 7) },
      { name: 'YFLOP/s', id: 'yflops', fn: SIPrefix('FLOPS', 8) },
    ],
  },
  {
    name: '密度',
    formats: [
      { name: 'parts-per-million (ppm)', id: 'ppm', fn: toFixedUnit('ppm') },
      { name: 'parts-per-billion (ppb)', id: 'conppb', fn: toFixedUnit('ppb') },
      { name: 'nanogram per cubic meter (ng/m³)', id: 'conngm3', fn: toFixedUnit('ng/m³') },
      { name: 'nanogram per normal cubic meter (ng/Nm³)', id: 'conngNm3', fn: toFixedUnit('ng/Nm³') },
      { name: 'microgram per cubic meter (μg/m³)', id: 'conμgm3', fn: toFixedUnit('μg/m³') },
      { name: 'microgram per normal cubic meter (μg/Nm³)', id: 'conμgNm3', fn: toFixedUnit('μg/Nm³') },
      { name: 'milligram per cubic meter (mg/m³)', id: 'conmgm3', fn: toFixedUnit('mg/m³') },
      { name: 'milligram per normal cubic meter (mg/Nm³)', id: 'conmgNm3', fn: toFixedUnit('mg/Nm³') },
      { name: 'gram per cubic meter (g/m³)', id: 'congm3', fn: toFixedUnit('g/m³') },
      { name: 'gram per normal cubic meter (g/Nm³)', id: 'congNm3', fn: toFixedUnit('g/Nm³') },
      { name: 'milligrams per decilitre (mg/dL)', id: 'conmgdL', fn: toFixedUnit('mg/dL') },
      { name: 'millimoles per litre (mmol/L)', id: 'conmmolL', fn: toFixedUnit('mmol/L') },
    ],
  },
  {
    name: '货币',
    formats: [
      { name: '人民币 (¥)', id: 'currencyCNY', fn: currency('¥') },
      { name: '美元 ($)', id: 'currencyUSD', fn: currency('$') },
      { name: '英镑 (£)', id: 'currencyGBP', fn: currency('£') },
      { name: '欧元 (€)', id: 'currencyEUR', fn: currency('€') },
      { name: '日元 (¥)', id: 'currencyJPY', fn: currency('¥') },
      { name: 'Rubles (₽)', id: 'currencyRUB', fn: currency('₽') },
      { name: 'Hryvnias (₴)', id: 'currencyUAH', fn: currency('₴') },
      { name: 'Real (R$)', id: 'currencyBRL', fn: currency('R$') },
      { name: 'Danish Krone (kr)', id: 'currencyDKK', fn: currency('kr', true) },
      { name: 'Icelandic Króna (kr)', id: 'currencyISK', fn: currency('kr', true) },
      { name: 'Norwegian Krone (kr)', id: 'currencyNOK', fn: currency('kr', true) },
      { name: 'Swedish Krona (kr)', id: 'currencySEK', fn: currency('kr', true) },
      { name: 'Czech koruna (czk)', id: 'currencyCZK', fn: currency('czk') },
      { name: 'Swiss franc (CHF)', id: 'currencyCHF', fn: currency('CHF') },
      { name: 'Polish Złoty (PLN)', id: 'currencyPLN', fn: currency('PLN') },
      { name: 'Bitcoin (฿)', id: 'currencyBTC', fn: currency('฿') },
      { name: 'Milli Bitcoin (฿)', id: 'currencymBTC', fn: currency('mBTC') },
      { name: 'Micro Bitcoin (฿)', id: 'currencyμBTC', fn: currency('μBTC') },
      { name: 'South African Rand (R)', id: 'currencyZAR', fn: currency('R') },
      { name: 'Indian Rupee (₹)', id: 'currencyINR', fn: currency('₹') },
      { name: 'South Korean Won (₩)', id: 'currencyKRW', fn: currency('₩') },
      { name: 'Indonesian Rupiah (Rp)', id: 'currencyIDR', fn: currency('Rp') },
      { name: 'Philippine Peso (PHP)', id: 'currencyPHP', fn: currency('PHP') },
      { name: 'Vietnamese Dong (VND)', id: 'currencyVND', fn: currency('đ', true) },
      { name: 'Turkish Lira (₺)', id: 'currencyTRY', fn: currency('₺', true) },
    ],
  },
  {
    name: '数据',
    formats: [
      { name: 'bytes(IEC)', id: 'bytes', fn: binaryPrefix('B') },
      { name: 'bytes(SI)', id: 'decbytes', fn: SIPrefix('B') },
      { name: 'bits(IEC)', id: 'bits', fn: binaryPrefix('b') },
      { name: 'bits(SI)', id: 'decbits', fn: SIPrefix('b') },
      { name: 'kibibytes', id: 'kbytes', fn: binaryPrefix('B', 1) },
      { name: 'kilobytes', id: 'deckbytes', fn: SIPrefix('B', 1) },
      { name: 'mebibytes', id: 'mbytes', fn: binaryPrefix('B', 2) },
      { name: 'megabytes', id: 'decmbytes', fn: SIPrefix('B', 2) },
      { name: 'gibibytes', id: 'gbytes', fn: binaryPrefix('B', 3) },
      { name: 'gigabytes', id: 'decgbytes', fn: SIPrefix('B', 3) },
      { name: 'tebibytes', id: 'tbytes', fn: binaryPrefix('B', 4) },
      { name: 'terabytes', id: 'dectbytes', fn: SIPrefix('B', 4) },
      { name: 'pebibytes', id: 'pbytes', fn: binaryPrefix('B', 5) },
      { name: 'petabytes', id: 'decpbytes', fn: SIPrefix('B', 5) },
    ],
  },
  {
    name: '流量',
    formats: [
      { name: 'packets/sec', id: 'pps', fn: SIPrefix('p/s') },
      { name: 'bytes/sec(IEC)', id: 'binBps', fn: binaryPrefix('B/s') },
      { name: 'bytes/sec(SI)', id: 'Bps', fn: SIPrefix('B/s') },
      { name: 'bits/sec(IEC)', id: 'binbps', fn: binaryPrefix('b/s') },
      { name: 'bits/sec(SI)', id: 'bps', fn: SIPrefix('b/s') },
      { name: 'kibibytes/sec', id: 'KiBs', fn: binaryPrefix('B/s', 1) },
      { name: 'kibibits/sec', id: 'Kibits', fn: binaryPrefix('b/s', 1) },
      { name: 'kilobytes/sec', id: 'KBs', fn: SIPrefix('B/s', 1) },
      { name: 'kilobits/sec', id: 'Kbits', fn: SIPrefix('b/s', 1) },
      { name: 'mebibytes/sec', id: 'MiBs', fn: binaryPrefix('B/s', 2) },
      { name: 'mebibits/sec', id: 'Mibits', fn: binaryPrefix('b/s', 2) },
      { name: 'megabytes/sec', id: 'MBs', fn: SIPrefix('B/s', 2) },
      { name: 'megabits/sec', id: 'Mbits', fn: SIPrefix('b/s', 2) },
      { name: 'gibibytes/sec', id: 'GiBs', fn: binaryPrefix('B/s', 3) },
      { name: 'gibibits/sec', id: 'Gibits', fn: binaryPrefix('b/s', 3) },
      { name: 'gigabytes/sec', id: 'GBs', fn: SIPrefix('B/s', 3) },
      { name: 'gigabits/sec', id: 'Gbits', fn: SIPrefix('b/s', 3) },
      { name: 'tebibytes/sec', id: 'TiBs', fn: binaryPrefix('B/s', 4) },
      { name: 'tebibits/sec', id: 'Tibits', fn: binaryPrefix('b/s', 4) },
      { name: 'terabytes/sec', id: 'TBs', fn: SIPrefix('B/s', 4) },
      { name: 'terabits/sec', id: 'Tbits', fn: SIPrefix('b/s', 4) },
      { name: 'pebibytes/sec', id: 'PiBs', fn: binaryPrefix('B/s', 5) },
      { name: 'pebibits/sec', id: 'Pibits', fn: binaryPrefix('b/s', 5) },
      { name: 'petabytes/sec', id: 'PBs', fn: SIPrefix('B/s', 5) },
      { name: 'petabits/sec', id: 'Pbits', fn: SIPrefix('b/s', 5) },
    ],
  },
  {
    name: '液体流量',
    formats: [
      { name: 'Gallons/min (gpm)', id: 'flowgpm', fn: toFixedUnit('gpm') },
      { name: 'Cubic meters/sec (cms)', id: 'flowcms', fn: toFixedUnit('cms') },
      { name: 'Cubic feet/sec (cfs)', id: 'flowcfs', fn: toFixedUnit('cfs') },
      { name: 'Cubic feet/min (cfm)', id: 'flowcfm', fn: toFixedUnit('cfm') },
      { name: 'Litre/hour', id: 'litreh', fn: toFixedUnit('L/h') },
      { name: 'Litre/min (L/min)', id: 'flowlpm', fn: toFixedUnit('L/min') },
      { name: 'milliLitre/min (mL/min)', id: 'flowmlpm', fn: toFixedUnit('mL/min') },
      { name: 'Lux (lx)', id: 'lux', fn: toFixedUnit('lux') },
    ],
  },
  {
    name: '力',
    formats: [
      { name: 'Newton-meters (Nm)', id: 'forceNm', fn: SIPrefix('Nm') },
      { name: 'Kilonewton-meters (kNm)', id: 'forcekNm', fn: SIPrefix('Nm', 1) },
      { name: 'Newtons (N)', id: 'forceN', fn: SIPrefix('N') },
      { name: 'Kilonewtons (kN)', id: 'forcekN', fn: SIPrefix('N', 1) },
    ],
  },
  {
    name: '哈希速度',
    formats: [
      { name: 'hashes/sec', id: 'Hs', fn: SIPrefix('H/s') },
      { name: 'kilohashes/sec', id: 'KHs', fn: SIPrefix('H/s', 1) },
      { name: 'megahashes/sec', id: 'MHs', fn: SIPrefix('H/s', 2) },
      { name: 'gigahashes/sec', id: 'GHs', fn: SIPrefix('H/s', 3) },
      { name: 'terahashes/sec', id: 'THs', fn: SIPrefix('H/s', 4) },
      { name: 'petahashes/sec', id: 'PHs', fn: SIPrefix('H/s', 5) },
      { name: 'exahashes/sec', id: 'EHs', fn: SIPrefix('H/s', 6) },
    ],
  },
  {
    name: '质量',
    formats: [
      { name: '毫克 (mg)', id: 'massmg', fn: SIPrefix('g', -1) },
      { name: '克 (g)', id: 'massg', fn: SIPrefix('g') },
      { name: '磅 (lb)', id: 'masslb', fn: toFixedUnit('lb') },
      { name: '千克 (kg)', id: 'masskg', fn: SIPrefix('g', 1) },
      { name: '吨 (t)', id: 'masst', fn: toFixedUnit('t') },
    ],
  },
  {
    name: '长度',
    formats: [
      { name: '毫米 (mm)', id: 'lengthmm', fn: SIPrefix('m', -1) },
      { name: 'inch (in)', id: 'lengthin', fn: toFixedUnit('in') },
      { name: 'feet (ft)', id: 'lengthft', fn: toFixedUnit('ft') },
      { name: '米 (m)', id: 'lengthm', fn: SIPrefix('m') },
      { name: '千米 (km)', id: 'lengthkm', fn: SIPrefix('m', 1) },
      { name: 'mile (mi)', id: 'lengthmi', fn: toFixedUnit('mi') },
    ],
  },
  {
    name: '压力',
    formats: [
      { name: 'Millibars', id: 'pressurembar', fn: SIPrefix('bar', -1) },
      { name: 'Bars', id: 'pressurebar', fn: SIPrefix('bar') },
      { name: 'Kilobars', id: 'pressurekbar', fn: SIPrefix('bar', 1) },
      { name: 'Pascals', id: 'pressurepa', fn: SIPrefix('Pa') },
      { name: 'Hectopascals', id: 'pressurehpa', fn: toFixedUnit('hPa') },
      { name: 'Kilopascals', id: 'pressurekpa', fn: toFixedUnit('kPa') },
      { name: 'Inches of mercury', id: 'pressurehg', fn: toFixedUnit('"Hg') },
      { name: 'PSI', id: 'pressurepsi', fn: scaledUnits(1000, ['psi', 'ksi', 'Mpsi']) },
    ],
  },
  {
    name: '辐射',
    formats: [
      { name: 'Becquerel (Bq)', id: 'radbq', fn: SIPrefix('Bq') },
      { name: 'curie (Ci)', id: 'radci', fn: SIPrefix('Ci') },
      { name: 'Gray (Gy)', id: 'radgy', fn: SIPrefix('Gy') },
      { name: 'rad', id: 'radrad', fn: SIPrefix('rad') },
      { name: 'Sievert (Sv)', id: 'radsv', fn: SIPrefix('Sv') },
      { name: 'milliSievert (mSv)', id: 'radmsv', fn: SIPrefix('Sv', -1) },
      { name: 'microSievert (µSv)', id: 'radusv', fn: SIPrefix('Sv', -2) },
      { name: 'rem', id: 'radrem', fn: SIPrefix('rem') },
      { name: 'Exposure (C/kg)', id: 'radexpckg', fn: SIPrefix('C/kg') },
      { name: 'roentgen (R)', id: 'radr', fn: SIPrefix('R') },
      { name: 'Sievert/hour (Sv/h)', id: 'radsvh', fn: SIPrefix('Sv/h') },
      { name: 'milliSievert/hour (mSv/h)', id: 'radmsvh', fn: SIPrefix('Sv/h', -1) },
      { name: 'microSievert/hour (µSv/h)', id: 'radusvh', fn: SIPrefix('Sv/h', -2) },
    ],
  },
  {
    name: '角速度',
    formats: [
      { name: 'Revolutions per minute (rpm)', id: 'rotrpm', fn: toFixedUnit('rpm') },
      { name: 'Hertz (Hz)', id: 'rothz', fn: SIPrefix('Hz') },
      { name: 'Kilohertz (kHz)', id: 'rotkhz', fn: SIPrefix('Hz', 1) },
      { name: 'Megahertz (MHz)', id: 'rotmhz', fn: SIPrefix('Hz', 2) },
      { name: 'Gigahertz (GHz)', id: 'rotghz', fn: SIPrefix('Hz', 3) },
      { name: 'Radians per second (rad/s)', id: 'rotrads', fn: toFixedUnit('rad/s') },
      { name: 'Degrees per second (°/s)', id: 'rotdegs', fn: toFixedUnit('°/s') },
    ],
  },
  {
    name: '温度',
    formats: [
      { name: '摄氏度 (°C)', id: 'celsius', fn: toFixedUnit('°C') },
      { name: '华氏度 (°F)', id: 'fahrenheit', fn: toFixedUnit('°F') },
      { name: '开尔文 (K)', id: 'kelvin', fn: toFixedUnit('K') },
    ],
  },
  {
    name: '时间',
    formats: [
      { name: '赫兹 (1/s)', id: 'hertz', fn: SIPrefix('Hz') },
      { name: '纳秒 (ns)', id: 'ns', fn: toNanoSeconds },
      { name: '毫秒 (µs)', id: 'µs', fn: toMicroSeconds },
      { name: '微秒 (ms)', id: 'ms', fn: toMilliSeconds },
      { name: '秒 (s)', id: 's', fn: toSeconds },
      { name: '分钟 (m)', id: 'm', fn: toMinutes },
      { name: '小时 (h)', id: 'h', fn: toHours },
      { name: '天 (d)', id: 'd', fn: toDays },
      { name: '毫秒单位时长 (ms)', id: 'dtdurationms', fn: toDurationInMilliseconds },
      { name: '秒单位时长 (s)', id: 'dtdurations', fn: toDurationInSeconds },
      { name: '时分秒时长 (hh:mm:ss)', id: 'dthms', fn: toDurationInHoursMinutesSeconds },
      { name: '日时分秒市场 (d hh:mm:ss)', id: 'dtdhms', fn: toDurationInDaysHoursMinutesSeconds },
      { name: '百次耗时 (s/100)', id: 'timeticks', fn: toTimeTicks },
      { name: '时钟毫秒 (ms)', id: 'clockms', fn: toClockMilliseconds },
      { name: '时钟秒 (s)', id: 'clocks', fn: toClockSeconds },
    ],
  },
  {
    name: '读写速度',
    formats: [
      { name: 'counts/sec (cps)', id: 'cps', fn: simpleCountUnit('c/s') },
      { name: 'ops/sec (ops)', id: 'ops', fn: simpleCountUnit('ops/s') },
      { name: 'requests/sec (rps)', id: 'reqps', fn: simpleCountUnit('req/s') },
      { name: 'reads/sec (rps)', id: 'rps', fn: simpleCountUnit('rd/s') },
      { name: 'writes/sec (wps)', id: 'wps', fn: simpleCountUnit('wr/s') },
      { name: 'I/O ops/sec (iops)', id: 'iops', fn: simpleCountUnit('io/s') },
      { name: 'events/sec (eps)', id: 'eps', fn: simpleCountUnit('evt/s') },
      { name: 'messages/sec (mps)', id: 'mps', fn: simpleCountUnit('msg/s') },
      { name: 'records/sec (rps)', id: 'recps', fn: simpleCountUnit('rec/s') },
      { name: 'rows/sec (rps)', id: 'rowsps', fn: simpleCountUnit('rows/s') },
      { name: 'counts/min (cpm)', id: 'cpm', fn: simpleCountUnit('c/m') },
      { name: 'ops/min (opm)', id: 'opm', fn: simpleCountUnit('ops/m') },
      { name: 'requests/min (rpm)', id: 'reqpm', fn: simpleCountUnit('req/m') },
      { name: 'reads/min (rpm)', id: 'rpm', fn: simpleCountUnit('rd/m') },
      { name: 'writes/min (wpm)', id: 'wpm', fn: simpleCountUnit('wr/m') },
      { name: 'events/min (epm)', id: 'epm', fn: simpleCountUnit('evts/m') },
      { name: 'messages/min (mpm)', id: 'mpm', fn: simpleCountUnit('msgs/m') },
      { name: 'records/min (rpm)', id: 'recpm', fn: simpleCountUnit('rec/m') },
      { name: 'rows/min (rpm)', id: 'rowspm', fn: simpleCountUnit('rows/m') },
    ],
  },
  {
    name: '速度',
    formats: [
      { name: 'meters/second (m/s)', id: 'velocityms', fn: toFixedUnit('m/s') },
      { name: 'kilometers/hour (km/h)', id: 'velocitykmh', fn: toFixedUnit('km/h') },
      { name: 'miles/hour (mph)', id: 'velocitymph', fn: toFixedUnit('mph') },
      { name: 'knot (kn)', id: 'velocityknot', fn: toFixedUnit('kn') },
    ],
  },
  {
    name: '体积',
    formats: [
      { name: 'millilitre (mL)', id: 'mlitre', fn: SIPrefix('L', -1) },
      { name: 'litre (L)', id: 'litre', fn: SIPrefix('L') },
      { name: 'cubic meter', id: 'm3', fn: toFixedUnit('m³') },
      { name: 'Normal cubic meter', id: 'Nm3', fn: toFixedUnit('Nm³') },
      { name: 'cubic decimeter', id: 'dm3', fn: toFixedUnit('dm³') },
      { name: 'gallons', id: 'gallons', fn: toFixedUnit('gal') },
    ],
  },
  {
    name: '布尔值',
    formats: [
      { name: '真 / 假', id: 'bool', fn: booleanValueFormatter('True', 'False') },
      { name: '是 / 否', id: 'bool_yes_no', fn: booleanValueFormatter('Yes', 'No') },
      { name: '开 / 关', id: 'bool_on_off', fn: booleanValueFormatter('On', 'Off') },
    ],
  },
]
