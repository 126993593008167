import { css } from '@emotion/css';
import React, { ChangeEvent } from 'react';

import { VariableSuggestion, GrafanaTheme2, DataLink } from '@grafana/data';

import { useStyles2 } from '../../themes/index';
import { isCompactUrl } from '../../utils/dataLinks';
import { Field } from '../Forms/Field';
import { Input } from '../Input/Input';
import { Switch } from '../Switch/Switch';

import { DataLinkInput } from './DataLinkInput';

interface DataLinkEditorProps {
  index: number;
  isLast: boolean;
  value: DataLink;
  suggestions: VariableSuggestion[];
  onChange: (index: number, link: DataLink, callback?: () => void) => void;
}

const getStyles = (theme: GrafanaTheme2) => ({
  listItem: css({
    marginBottom: theme.spacing(),
  }),
  infoText: css({
    paddingBottom: theme.spacing(2),
    marginLeft: '66px',
    color: theme.colors.text.secondary,
  }),
});

export const DataLinkEditor = React.memo(({ index, value, onChange, suggestions, isLast }: DataLinkEditorProps) => {
  const styles = useStyles2(getStyles);

  const onUrlChange = (url: string, callback?: () => void) => {
    onChange(index, { ...value, url }, callback);
  };
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(index, { ...value, title: event.target.value });
  };

  const onOpenInNewTabChanged = () => {
    onChange(index, { ...value, targetBlank: !value.targetBlank });
  };

  return (
    <div className={styles.listItem}>
      <Field label="抬头">
        <Input value={value.title} onChange={onTitleChange} placeholder="" />
      </Field>

      <Field label="URL" invalid={isCompactUrl(value.url)} error="explorer 的 url 将会被废弃。">
        <DataLinkInput value={value.url} onChange={onUrlChange} suggestions={suggestions} />
      </Field>

      <Field label="新 tab 中打开">
        <Switch value={value.targetBlank || false} onChange={onOpenInNewTabChanged} />
      </Field>

      {isLast && (
        <div className={styles.infoText}>
          数据链接中可以引用图表、标签、数值。Cmd+空格、Ctrl+空格、或者 使用 $ 打开变量推荐。
        </div>
      )}
    </div>
  );
});

DataLinkEditor.displayName = 'DataLinkEditor';
