import { css } from '@emotion/css';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GrafanaTheme2, locationUtil, textUtil } from '@grafana/data';
import { Dropdown, ToolbarButton, useStyles2 } from '@grafana/ui';
import { config } from 'app/core/config';
import { contextSrv } from 'app/core/core';
import { useSelector } from 'app/types';

import { Branding } from '../../Branding/Branding';
import { OrganizationSwitcher } from '../OrganizationSwitcher/OrganizationSwitcher';
import { QuickAdd } from '../QuickAdd/QuickAdd';
import { TOP_BAR_LEVEL_HEIGHT2 } from '../types';

import { SignInLink } from './SignInLink';
import { TopNavBarMenu } from './TopNavBarMenu';
import { TopSearchBarCommandPaletteTrigger } from './TopSearchBarCommandPaletteTrigger';
import { TopSearchBarSection } from './TopSearchBarSection';

export const TopSearchBar = React.memo(function TopSearchBar() {
  const styles = useStyles2(getStyles);
  const navIndex = useSelector((state) => state.navIndex);
  const location = useLocation();

  const profileNode = navIndex['profile'];

  let homeUrl = config.appSubUrl || '/';
  if (!config.bootData.user.isSignedIn && !config.anonymousEnabled) {
    homeUrl = textUtil.sanitizeUrl(locationUtil.getUrlForPartial(location, { forceLogin: 'true' }));
  }

  return (
    <div className={styles.layout}>
      <TopSearchBarSection align="left">
        <a className={styles.logo} href={homeUrl} title="Go to home">
          <Branding.MenuLogo className={styles.img} />
        </a>
        <div className={styles.title}>设备状态监测系统</div>
      </TopSearchBarSection>

      <TopSearchBarSection align="center">
        {/* <TopSearchBarCommandPaletteTrigger /> */}
        <Clock className={styles.clock} />
      </TopSearchBarSection>

      <TopSearchBarSection align="right">
        <OrganizationSwitcher />
        {/* <QuickAdd /> */}
        {!contextSrv.user.isSignedIn && <SignInLink />}
        {profileNode && (
          <Dropdown overlay={() => <TopNavBarMenu node={profileNode} />} placement="bottom-end">
            <ToolbarButton
              className={styles.profileButton}
              imgSrc={contextSrv.user.gravatarUrl}
              imgAlt="User avatar"
              aria-label="Profile"
            />
          </Dropdown>
        )}
      </TopSearchBarSection>
    </div>
  );
});

const getStyles = (theme: GrafanaTheme2) => ({
  layout: css({
    height: TOP_BAR_LEVEL_HEIGHT2,
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    borderBottom: `1px solid ${theme.colors.border.weak}`,
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1.5fr minmax(240px, 1fr) 1.5fr', // search should not be smaller than 240px
      display: 'grid',

      justifyContent: 'flex-start',
    },
  }),
  img: css({
    height: 'auto',
    width: theme.spacing(8),
    // marginBottom: theme.spacing(-2)
  }),
  logo: css({
    display: 'flex',
  }),
  title: css({
    fontSize: theme.spacing(3),
    color: theme.visualization.getColorByName('dark-blue'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  clock: css({
    width: '100%',
    color: theme.colors.primary.text,
    fontSize: theme.typography.size.lg,
    color: theme.visualization.getColorByName('dark-blue'),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  profileButton: css({
    padding: theme.spacing(0, 0.25),
    img: {
      borderRadius: theme.shape.radius.circle,
      height: '24px',
      marginRight: 0,
      width: '24px',
    },
  }),
});

function Clock({ className }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // 在组件卸载时清除定时器，以防止内存泄漏
    return () => clearInterval(intervalID);
  }, []); // 空数组作为依赖，只在组件挂载和卸载时运行

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false, // 使用24小时制
  };

  const formattedTime = time.toLocaleString('zh-CN', options);
  const [formattedDate, weekDay, formattedClock] = formattedTime.split(' ');

  return (
    <div className={className}>
      <div>
        {' '}
        {formattedDate} {weekDay} {formattedClock}
      </div>
    </div>
  );
}
